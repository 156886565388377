//Lifecycle
.lifecycle {

    #body-inner {

        .level {
            position: relative;

            h3 {
                font-size: 18px;
                position: absolute;
                margin: 0;
                padding: 2px 10px 4px;
                left: 0;
                z-index: 1000;
                color: #fff;

            }

            .level {
                margin-top: 0.5rem;
                margin-left: -1rem;
                margin-right: 1rem;
                margin-bottom: 0.5rem;
                padding-bottom: 1rem;
            }

            ol li {
                padding-top: 0.1rem;
                margin-top: 0.2rem;
            }

            & > ol {
                margin-top: 2rem;
            }

            &.level-1 {
                background: lighten($turquoise,56%);
                border: 4px solid $turquoise;
                color: $turquoise;
                margin-bottom: 1rem;

                h3 {
                    background: $turquoise;
                }
            }

            &.level-2   {
                background: lighten($emerald,49%);
                border: 4px solid $emerald;
                color: $emerald;

                h3 {
                    background: $emerald;
                }
            }

            &.level-3 {
                background: lighten($peter-river,44%);
                border: 4px solid $peter-river;
                color: $peter-river;

                h3 {
                    background: $peter-river;
                }
            }

            &.level-4 {
                background: lighten($wet-asphalt,63%);
                border: 4px solid $wet-asphalt;
                color: $wet-asphalt;

                h3 {
                    background: $wet-asphalt;
                }
            }
        }
    }
}