#top-bar {
    position: fixed;
    display: flex;
    background: $bg-color;
    border-bottom: 1px solid darken($bg-color, 4%);
    z-index: 2;
    align-items: center;

    top: 0;
    right: 0;
    left: $sidebar-width;

    @include breakpoint(lg) {
        left: 0;
    }

    #breadcrumbs {
        padding-left: 0.5rem;
        line-height: 1;
        height: auto;
        width: 100%;

        @include breakpoint(sm) {
            * {
                display: none;
            }
        }

        > span, > a {
            padding: 0 .5rem !important;

            span {
                padding: 0 !important;
            }
        }

        @include breakpoint(lg) {
            padding-left: 2rem;
        }
        

    }

    #navigation {
        width: 400px;
        display: flex;

        > a, span {
            width: 50%;
            text-align: center;
            border-left: 1px solid darken($bg-color, 4%);

            font-size: 27px;
            text-decoration: none;
            transition: background-color 0.5s ease, color 0.5s ease;

            &.disabled {
                color: lighten($body-font-color, 50%);

            }
        }

        > a {
            &:hover {
                background: darken($bg-color, 4%);
                color: $link-color-dark;
            }
        }
    }

    .progress {
        position: absolute;
        bottom: -1px;
        background: linear-gradient(to right, $primary-color var(--scroll), transparent 0);
        background-repeat: no-repeat;
        width: 100%;
        height: 1px;
        z-index: 1;
    }
}